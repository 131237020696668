import { motion, useTransform, useViewportScroll } from 'framer-motion';
import React from 'react';
import WaveWhite from '../../images/wave-white-pattern.png';
import AppStoreIconDark from '../atoms/AppStoreIconDark';
import BeansLeft from '../atoms/BeansLeft';
import Button from '../atoms/Button';
import FaqIcon from '../atoms/FaqIcon';
import GooglePlayIconDark from '../atoms/GooglePlayIconDark';

interface RewardsFaqSectionProps {
  heading: string;
  body: string;
  faqHeading: string;
  faqBody: string;
  buttonLabel: string;
  buttonUrl: string;
  appStoreUrl: string;
  googlePlayUrl: string;
  externalButtonLabel: string;
  externalButtonUrl: string;
}

const RewardsFaqSection = ({
  heading,
  body,
  faqBody,
  faqHeading,
  buttonLabel,
  buttonUrl,
  appStoreUrl,
  googlePlayUrl,
  externalButtonLabel,
  externalButtonUrl,
}: RewardsFaqSectionProps) => {
  const { scrollY } = useViewportScroll();
  const x = useTransform(scrollY, [0, 10000], [0, -1000]);
  const y = useTransform(scrollY, [0, 5000], [100, -700]);

  return (
    <section className="relative pt-24 pb-32 bg-linen">
      <BeansLeft className="absolute left-0 -z-1" style={{ y }} />
      <div className="absolute left-0 right-0 z-10 h-8 -mt-2.5 overflow-hidden top-0">
        <motion.div
          className="w-[10000px] h-full bg-repeat-x"
          style={{
            backgroundImage: `url(${WaveWhite})`,
            backgroundSize: '1.5% 100%',
            x: x,
          }}
        />
      </div>
      <div className="container items-center space-y-8 lg:justify-end lg:flex lg:space-y-0">
        <div className="lg:w-5/12 lg:pt-16 lg:pr-12 lg:hidden">
          <h2 className="mb-4 u-h2">{heading}</h2>
          <p className="mb-6 u-p">{body}</p>
          <div className="flex items-center justify-start mb-6 space-x-4">
            <a href={appStoreUrl} target="_blank" rel="noopener noreferrer">
              <AppStoreIconDark />
            </a>
            <a href={googlePlayUrl} target="_blank" rel="noopener noreferrer">
              <GooglePlayIconDark />
            </a>
          </div>
          <div className="flex items-center">
            <Button to={externalButtonUrl} variant="outline">
              {externalButtonLabel}
            </Button>
          </div>
        </div>
        <div className="z-30 lg:w-7/12">
          <div className="bg-white rounded-[10px] pt-8 px-8 md:px-16 pb-12">
            <FaqIcon className="w-auto mb-6 h-36" />
            <div className="max-w-[500px]">
              <h3 className="mb-4 u-h2">{faqHeading}</h3>
              <p className="mb-6 u-p">{faqBody}</p>
              <Button to={buttonUrl} variant="default">
                {buttonLabel}
              </Button>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default RewardsFaqSection;
