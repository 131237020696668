import { motion, useTransform, useViewportScroll } from 'framer-motion';
import { graphql, useStaticQuery } from 'gatsby';
import React from 'react';
import AppHighlightImage from '../../images/app-highlight-image.png';
import AppStoreIconDark from '../atoms/AppStoreIconDark';
import Button from '../atoms/Button';
import CupIcon from '../atoms/CupIcon';
import DonutNew from '../atoms/DonutNew';
import GooglePlayIconDark from '../atoms/GooglePlayIconDark';
import Sparkle1 from '../atoms/Sparkle1';
import Sparkle2 from '../atoms/Sparkle2';
import Sparkle3 from '../atoms/Sparkle3';

interface RewardsHeroSectionProps {
  heading: string;
  body: string;
  appStoreUrl: string;
  googlePlayUrl: string;
}

const RewardsHeroSection = ({
  heading,
  body,
  appStoreUrl,
  googlePlayUrl,
  buttonLabel,
  buttonUrl,
}: RewardsHeroSectionProps) => {
  const { scrollY } = useViewportScroll();
  const y1 = useTransform(scrollY, [0, 1000], [100, -150]);
  const y2 = useTransform(scrollY, [0, 1000], [200, -90]);
  const y3 = useTransform(scrollY, [0, 1000], [250, 50]);
  const yDonutUnder = useTransform(scrollY, [0, 2000], [150, 50]);
  const yDonutOver = useTransform(scrollY, [0, 2000], [50, -300]);
  const {
    wp: {
      bannerSettings: {
        bannerSettings: { visible: bannerVisible },
      },
    },
  } = useStaticQuery(graphql`
    query RewardsBannerSettings {
      wp {
        bannerSettings {
          bannerSettings {
            visible
          }
        }
      }
    }
  `);

  return (
    <section
      className={`relative ${
        bannerVisible ? 'pt-64' : 'pt-48'
      } pb-24 overflow-hidden lg:pt-0`}
    >
      <div className="absolute top-0 left-0 right-0 bottom-24 bg-linen -z-1" />
      <div className="container items-center lg:justify-end lg:flex">
        <div className="pr-16 lg:hidden">
          <div className="max-w-xl mx-auto text-navy-blue lg:max-w-none">
            <h1 className="mb-4 u-h2">{heading}</h1>
            <p className="mb-6 u-p">{body}</p>

            <div className="flex items-center justify-start mb-6 space-x-4">
              <a href={appStoreUrl} target="_blank" rel="noopener noreferrer">
                <AppStoreIconDark />
              </a>
              <a href={googlePlayUrl} target="_blank" rel="noopener noreferrer">
                <GooglePlayIconDark />
              </a>
            </div>

            <div className="flex items-center">
              <Button to={buttonUrl} variant="outline">
                {buttonLabel}
              </Button>
            </div>
          </div>
        </div>
        <div className="relative lg:w-7/12 ">
          <Sparkle1
            className="absolute -z-1"
            style={{ left: '54%', bottom: '94%', y: y1 }}
          />
          <Sparkle2
            className="absolute -z-1"
            style={{ left: '8%', top: '44%', y: y2 }}
          />
          <Sparkle3
            className="absolute -z-1"
            style={{ bottom: '31%', right: '13%', y: y3 }}
          />

          <div className="mx-auto max-w-sm lg:max-w-[584px] relative top-12 lg:top-24">
            <motion.div
              className="absolute -right-8 -z-1"
              style={{ y: yDonutUnder }}
            >
              <CupIcon className="h-auto transform w-28 rot" />
            </motion.div>
            <img
              src={AppHighlightImage}
              alt="The Salty mobile app screenshot"
              className="w-full h-auto"
            />
            <motion.div
              className="absolute left-0 top-96"
              style={{ y: yDonutOver }}
            >
              <DonutNew className="relative hidden h-auto w-52 sm:block xl:w-64 -top-36 xl:top-0" />
            </motion.div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default RewardsHeroSection;
