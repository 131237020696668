import { graphql } from 'gatsby';
import React from 'react';
import AppStoreIconDark from '../components/atoms/AppStoreIconDark';
import Button from '../components/atoms/Button';
import Layout from '../components/base/Layout';
import Seo from '../components/base/Seo';
import NewsletterSection from '../components/organisms/NewsletterSection';
import { PromoPopup } from '../components/organisms/PromoPopup';
import RewardsBenefitsSection from '../components/organisms/RewardsBenefitsSection';
import RewardsFaqSection from '../components/organisms/RewardsFaqSection';
import RewardsHeroSection from '../components/organisms/RewardsHeroSection';
import GooglePlayIconDark from '../images/GooglePlayIconDark.svg';

const Rewards = ({
  data: {
    page,
    wp: {
      popupSettings: { popupSettings },
    },
  },
}) => {
  return (
    <>
      <Layout>
        <Seo post={page} />

        <div className="relative">
          <div className="container z-30 items-center hidden pb-48 pointer-events-none lg:sticky lg:flex top-64">
            <div className="pr-16 pointer-events-auto lg:w-5/12">
              <div className="max-w-xl mx-auto text-navy-blue lg:max-w-none">
                <h1 className="mb-4 u-h2">
                  {page.rewards.rewardsHero.heading}
                </h1>
                <p className="mb-6 u-p">{page.rewards.rewardsHero.body}</p>

                <div className="flex items-center justify-start mb-6 space-x-4">
                  <a
                    href={page.rewards.rewardsHero.appStoreUrl}
                    target="_blank"
                    rel="noopener noreferrer"
                    className="relative"
                    aria-label="Download on the App Store"
                  >
                    <AppStoreIconDark className="opacity-0" />

                    <div
                      className="absolute transition-all origin-center transform left-1/2 top-1/2 hover:scale-105"
                      style={{ marginTop: '-22px', marginLeft: '-50%' }}
                    >
                      <AppStoreIconDark />
                    </div>
                  </a>
                  <a
                    href={page.rewards.rewardsHero.googlePlayUrl}
                    target="_blank"
                    rel="noopener noreferrer"
                    className="relative"
                    aria-label="Download on the Google Play Store"
                  >
                    <img
                      src={GooglePlayIconDark}
                      alt="Google Play"
                      className="opacity-0"
                    />
                    <div
                      className="absolute transition-all origin-center transform left-1/2 top-1/2 hover:scale-105"
                      style={{ marginTop: '-22px', marginLeft: '-50%' }}
                    >
                      <img src={GooglePlayIconDark} alt="Google Play" />
                    </div>
                  </a>
                </div>

                <div className="flex items-center">
                  <Button
                    to={page.rewards.rewardsHero.buttonUrl}
                    variant="outline"
                  >
                    {page.rewards.rewardsHero.buttonLabel}
                  </Button>
                </div>
              </div>
            </div>
          </div>
          <div className="lg:-mt-[500px] xl:-mt-[430px]">
            <RewardsHeroSection {...page.rewards.rewardsHero} />
            <RewardsBenefitsSection {...page.rewards.benefitsSection} />
            <RewardsFaqSection
              {...page.rewards.faqSection}
              appStoreUrl={page.rewards.rewardsHero.appStoreUrl}
              googlePlayUrl={page.rewards.rewardsHero.googlePlayUrl}
              externalButtonLabel={page.rewards.rewardsHero.buttonLabel}
              externalButtonUrl={page.rewards.rewardsHero.buttonUrl}
            />
          </div>
        </div>

        <NewsletterSection isLinen />
      </Layout>
      <PromoPopup {...popupSettings} />
    </>
  );
};

export const pageQuery = graphql`
  query RewardsPageQuery($id: String!) {
    page: wpPage(id: { eq: $id }) {
      title
      ...SEO
      rewards {
        rewardsHero {
          heading
          body
          appStoreUrl
          googlePlayUrl
          buttonUrl
          buttonLabel
        }
        benefitsSection {
          heading
          body
          benefits {
            title
            description
            icon {
              localFile {
                url
              }
            }
          }
          youtubeVideoId
        }
        faqSection {
          heading
          body
          faqHeading
          faqBody
          buttonLabel
          buttonUrl
        }
      }
    }
    wp {
      popupSettings {
        popupSettings {
          popupEnabled
          image {
            altText
            localFile {
              url
              childImageSharp {
                gatsbyImageData(width: 1024, placeholder: BLURRED)
              }
            }
          }
          url
        }
      }
    }
  }
`;

export default Rewards;
